<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">量规列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="isadd = true">添加量规</el-button>
		</div>
		<el-scrollbar class="dd" v-loading="loadingall">
			<el-tree highlight-current accordion :data="treedata" :props="defaultProps">
				<template #default="{ node, data }">
					<span class="custom-tree-node">
						<span>{{ data.rubricName }}  <el-tag style="margin-left: 20px;" type="success" v-if="data.categoryName" size="small">{{data.categoryName}}</el-tag>
							<el-tag size="small" style="margin-left: 20px;" type="warning" v-if="data.subjectList!=null" v-for="item in data.subjectList" :key="item.id">{{item.subjectName}}</el-tag>
						</span>
						<span>
							<el-tag size="small" @click="editdata(data)">编辑</el-tag>
							<el-tag size="small" style="margin-left: 5px;" type="danger" @click="deletedata(data)">删除
							</el-tag>
							<!-- <a @click="append(data)"> Append </a>
							<a style="margin-left: 8px" @click="remove(node, data)"> Delete </a> -->
						</span>
					</span>
				</template>
			</el-tree>
		</el-scrollbar>
	</div>



	<el-dialog v-model="isadd" title="添加量规" width="20%">
		<div class="row">
			<div class="row-title">父级
			</div>
			<el-cascader style="width: 100%;" @change="parentchange" :options="treedata" :props="props1" clearable />
		</div>
		<div class="row">
			<div class="row-title">量规名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.rubricName" placeholder="请输入要添加的量规名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">量规描述<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.rubricDesc" placeholder="请输入要添加的量规描述" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">类型<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select style="width: 100%;" v-model="addjson.type" placeholder="请选择类型" clearable>
				<el-option value="1" label="作业"/>
				<el-option value="2" label="任务"/>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">量规分类
			</div>
			<el-select :disabled="!isedit" v-model="addjson.categoryId" style="width: 100%;" clearable>
				<el-option v-for="item in rubriccategorieslist" :label="item.categoryName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">学科
			</div>
			<el-select :disabled="!isedit" style="width: 100%;" v-model="addjson.subjectRubricRelationList" value-key="subjectId" clearable
				multiple collapse-tags collapse-tags-tooltip>
				<el-option v-for="item in subjectslist" :label="item.subjectName" :value="{subjectId:item.id}">
				</el-option>
			</el-select>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isadd = false">取消</el-button>
				<el-button type="primary" @click="submitadd" :loading="loading">添加</el-button>
			</span>
		</template>
	</el-dialog>

<el-dialog v-model="iseditdata" title="修改量规" width="20%">
		<div class="row">
			<div class="row-title">父级
			</div>
			<el-cascader v-model="editjson.parentId" style="width: 100%;" @change="parentchange" :options="treedata" :props="props1" clearable />
		</div>
		<div class="row">
			<div class="row-title">量规名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.rubricName" placeholder="请输入要添加的量规名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">量规描述<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.rubricDesc" placeholder="请输入要添加的量规描述" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">类型<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select style="width: 100%;" v-model="editjson.type" placeholder="请选择类型" clearable>
				<el-option value="1" label="作业"/>
				<el-option value="2" label="任务"/>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">量规分类
			</div>
			<el-select :disabled="!isedit" v-model="editjson.categoryId" style="width: 100%;" clearable>
				<el-option v-for="item in rubriccategorieslist" :label="item.categoryName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">学科
			</div>
			<el-select :disabled="!isedit" style="width: 100%;" v-model="editjson.subjectRubricRelationList" value-key="subjectId" clearable
				multiple collapse-tags collapse-tags-tooltip>
				<el-option v-for="item in subjectslist" :label="item.subjectName" :value="{subjectId:item.id}">
				</el-option>
			</el-select>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="iseditdata = false">取消</el-button>
				<el-button type="primary" @click="submitedit" :loading="loading">修改</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		rubricstree,
		rubricsadd,
		rubricsedit,
		rubricsdelete,
		subjectslist,
		rubriccategorieslist
	} from "../../util/api"
	export default {
		data() {
			return {
				loadingall: false,
				isadd: false,
				iseditdata: false,
				loading: false,
				treedata: null,
				defaultProps: {
					label: 'rubricName',
					children: 'children'
				},
				props1: {
					value: 'id',
					label: 'rubricName',
					children: 'children',
					expandTrigger: 'hover',
					checkStrictly: true
				},
				addjson: {
					rubricName: null, //量规名称
					rubricDesc: null, //量规描述
					subjectRubricRelationList: [], //量规学科
					categoryId: null, //量规分类ID
					parentId: null,//父级
					type: '1'
				},
				editjson: {
					id: null,
					rubricName: null, //量规名称
					rubricDesc: null, //量规描述
					subjectRubricRelationList: [], //量规学科
					categoryId: null, //量规分类ID
					parentId: null ,//父级
					type: null
				},
				subjectslist: null, //学科列表
				rubriccategorieslist: null, //量规分类列表
				isedit: true //是否能选择
			}
		},
		created() {
			this.gettree()
			this.getsubjectslist()
			this.getrubriccategorieslist()
		},
		methods: {
			submitedit(){
				if (!this.editjson.rubricName) {
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				} else if (!this.editjson.rubricDesc) {
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				} else if (!this.editjson.type) {
					this.$message({
						type: 'warning',
						message: '请选择类型',
					})
				} else {
					this.loading = true
					rubricsedit(this.editjson).then((res) => {
						this.loading = false
						this.iseditdata = false
						this.$message({
							type: 'success',
							message: '修改成功',
						})
						this.gettree()
					}).catch((error) => {
						this.loading = false
					})
				}
			},
			editdata(data){
				this.editjson = {
					id: data.id,
					rubricName: data.rubricName, //量规名称
					rubricDesc: data.rubricDesc, //量规描述
					subjectRubricRelationList: data.subjectRubricRelationList, //量规学科
					categoryId: data.categoryId, //量规分类ID
					parentId: data.parentId ,//父级
					type: data.type
				}
				if(data.subjectList){
					let nlist = []
					data.subjectList.forEach((item)=>{
						nlist.push({
							subjectId: item.id
						})
					})
					this.editjson.subjectRubricRelationList = nlist
				}
				if(data.parentId==null||data.parentId == 0){
					this.isedit = true
				}else{
					this.isedit = false
				}
				this.iseditdata = true
			},
			parentchange(value){
				this.isedit = false
				let selectdata = this.treedata.find(item=> item.id == value[0])
				
				if(selectdata.subjectList){
					let nlist = []
					selectdata.subjectList.forEach((item)=>{
						nlist.push({
							subjectId: item.id
						})
					})
					this.addjson.subjectRubricRelationList = nlist
				}
				
				this.addjson.categoryId = selectdata.categoryId
				this.addjson.parentId = value[value.length-1]
			},
			submitadd() {
				if (!this.addjson.rubricName) {
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				} else if (!this.addjson.rubricDesc) {
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				} else if (!this.addjson.type) {
					this.$message({
						type: 'warning',
						message: '请选择类型',
					})
				} else {
					this.loading = true
					rubricsadd(this.addjson).then((res) => {
						this.loading = false
						this.isadd = false
						this.$message({
							type: 'success',
							message: '添加成功',
						})
						this.addjson = {
							rubricName: null, //量规名称
							rubricDesc: null, //量规描述
							subjectRubricRelationList: [], //量规学科
							categoryId: null, //量规分类ID
							parentId: null ,//父级
							type: '1'
						}
						this.gettree()
					}).catch((error) => {
						this.loading = false
					})
				}
			},
			getrubriccategorieslist() {
				rubriccategorieslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.rubriccategorieslist = res.data.list
				})
			},
			getsubjectslist() {
				subjectslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.subjectslist = res.data.list
				})
			},
			deletedata(data) {
				this.$confirm('是否删除量规“' + data.rubricName + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					rubricsdelete(data.id).then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.gettree()
					})
				})
			},
			gettree() {
				this.loadingall = true
				rubricstree().then((res) => {
					this.treedata = res.data
					this.loadingall = false
				})
			}
		}
	}
</script>

<style scoped>
	.flex {
		display: flex;

	}

	.dd {
		height: calc(100vh - 200px);
		padding: 30px;
		box-sizing: border-box;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}
</style>
